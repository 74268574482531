'use client';

import {
  BlocksRenderer,
  type BlocksContent,
} from '@strapi/blocks-react-renderer';
import Image from 'next/image';
import Link from 'next/link';

import { toClassName } from 'helpers/format';
import { handleUseStrapiImageUrl } from 'helpers/strapi';

import { TNextClientComponent } from 'types/next';

import { styles } from '.';

type TProps = {
  content: BlocksContent;
  styles?: {
    paragraph?: string;
  };
};

const BlockRendererClient: TNextClientComponent<TProps> = (props) => {
  const { content } = props;

  if (!content) return null;

  return (
    <BlocksRenderer
      content={content}
      blocks={{
        paragraph: ({ children }) => {
          return (
            <p className={toClassName(styles.block.paragraph)}>{children}</p>
          );
        },
        heading: ({ children, level }) => {
          switch (level) {
            case 1:
              return (
                <h1 className={toClassName(styles.block.h1)}>{children}</h1>
              );
            case 2:
              return (
                <h2 className={toClassName(styles.block.h2)}>{children}</h2>
              );
            case 3:
              return (
                <h3 className={toClassName(styles.block.h3)}>{children}</h3>
              );
            case 4:
              return (
                <h4 className={toClassName(styles.block.h4)}>{children}</h4>
              );
            case 5:
              return (
                <h5 className={toClassName(styles.block.h5)}>{children}</h5>
              );
            case 6:
              return (
                <h6 className={toClassName(styles.block.h6)}>{children}</h6>
              );
            default:
              return (
                <h1 className={toClassName(styles.block.h1)}>{children}</h1>
              );
          }
        },
        // list: ({ format }) => {
        //   return <ul></ul>
        // },
        // quote: ({ children }) => {
        //   return <blockquote>{children}</blockquote>;
        // },
        // code: ({ children }) => {
        //   return <code>{children}</code>;
        // },
        image: ({ image }) => {
          return (
            <Image
              src={handleUseStrapiImageUrl(image.url)}
              width={image.width}
              height={image.height}
              alt={image.alternativeText || 'no-alt'}
            />
          );
        },
        link: ({ children, url }) => {
          return (
            <Link
              href={url}
              // target="_blank"
              className={toClassName(styles.block.link)}
            >
              {children}
            </Link>
          );
        },
      }}
      modifiers={
        {
          // bold: ({ children }) => <strong>{children}</strong>,
          // italic: ({ children }) => <span className="italic">{children}</span>,
        }
      }
    />
  );
};

export default BlockRendererClient;
